import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberInput]',
})
export class PhoneNumberInputDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement;
    let inputValue = inputElement.value;

    // Remove all non-numeric characters from the input value
    inputValue = inputValue.replace(/\D/g, '');

    // Ensure the input starts with '+36' (the Hungarian country code)
    if (!inputValue.startsWith('36')) {
      inputValue = '36' + inputValue;
    }

    // Format the phone number as per Hungarian format (06-XX-XXX-XXXX)
    const formattedValue = this.formatPhoneNumber(inputValue);

    // Update the input field value with the formatted phone number
    inputElement.value = formattedValue;
  }

  private formatPhoneNumber(value: string): string {
    // Implement custom formatting logic for Hungarian phone numbers
    if (value.length <= 2) {
      return value; // No need to format yet
    } else if (value.length <= 4) {
      return `+${value.slice(0, 2)}-${value.slice(2)}`;
    } else if (value.length <= 6) {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4)}`;
    } else if (value.length <= 8) {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 7)}-${value.slice(7)}`;
    } else {
      return `+${value.slice(0, 2)}-${value.slice(2, 4)}-${value.slice(4, 7)}-${value.slice(7, 11)}`;
    }
  }
}
