import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class WeatherPageService {
  private readonly _backgroundImages = {
    national: 'assets/images/weather/national_background_' + this.translateService.currentLang + '.svg',
    settlements: [{ settlement: 'Budapest', backgroundImage: 'assets/images/weather/budapest_background.svg' }],
  };

  private readonly _settlements = [
    'Komárom',
    'Esztergom',
    'Szentendre',
    'Visegrád',
    'Vác',
    'Gödöllő',
    'Budapest',
    'Dunaharaszti',
    'Vecsés',
    'Székesfehérvár',
    'Gárdony',
    'Velence',
    'Ráckeve',
    'Szombathely',
    'Győr',
    'Veszprém',
    'Pécs',
    'Miskolc',
    'Szeged',
    'Debrecen',
    'Nyíregyháza',
    'Békéscsaba',
  ];

  private readonly _headerImageUrl = '/assets/images/weather/Idojaras-header-osz-1920.webp';
  private readonly _thematicIconKey = 'thematic-icon-18';
  private readonly _selectedSettlement = 'Budapest';

  constructor(private readonly translateService: TranslateService) {}

  get settlements(): string[] {
    return this._settlements;
  }
  get headerImageUrl(): string {
    return this._headerImageUrl;
  }
  get thematicIconKey(): string {
    return this._thematicIconKey;
  }
  get selectedSettlement(): string {
    return this._selectedSettlement;
  }

  getBackgroundImage(settlement?: string): string {
    if (!settlement) {
      return this._backgroundImages.national;
    }
    return this._backgroundImages.settlements.find((s) => s.settlement === settlement)?.backgroundImage || '';
  }
}
