import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TrendencyReqService } from 'trendency/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly accessTokenKey = makeStateKey<string>('access_token');

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly transferState: TransferState,
    private readonly reqService: TrendencyReqService,
    private readonly translate: TranslateService
  ) {}

  get lang(): string {
    return this.translate.currentLang;
  }

  login$(data: object): Observable<{ token: string }> {
    return this.reqService.post(`${this.lang}/kpp/login_check`, data).pipe(
      tap((response) => {
        this.setAccessToken(response.token);
      }),
      catchError((error) => throwError(error))
    );
  }

  resetPassword$(username: string, recaptcha: string): Observable<any> {
    return this.reqService.post(`${this.lang}/kpp/password-reset/request`, { username, recaptcha });
  }

  changePassword$(password: string, token: string): Observable<any> {
    return this.reqService.post(`${this.lang}/kpp/password-reset/change`, { password, token });
  }

  checkResetToken$(token: string): Observable<any> {
    return this.reqService.get(`${this.lang}/kpp/password-reset/check/${token}`);
  }

  setAccessToken(token: string): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('access_token', token);
    } else if (isPlatformServer(this.platformId)) {
      this.transferState.set(this.accessTokenKey, token);
    }
  }

  getAccessToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem('access_token') as string;
    } else if (isPlatformServer(this.platformId)) {
      return this.transferState.get<string>(this.accessTokenKey, '');
    }
    return '';
  }

  deleteAccessToken(): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('access_token');
    } else if (isPlatformServer(this.platformId)) {
      this.transferState.remove(this.accessTokenKey);
    }
  }
}
