<form class="form-container" [formGroup]="form" *ngIf="formBuildDone">
  <form *ngIf="visiblePagesCount > 0" class="form-page" id="fPage" [formGroupName]="'formPage' + selectedPage">
    <div
      *ngFor="let c of formData.pages[selectedPage].components; let i = index"
      [ngClass]="{ third: c.width === 'third', half: c.width === 'half', full: c.width === 'full' || !c.width }"
    >
      <app-form-heading *ngIf="c.type === 'heading'" [formTitle]="c.title" [formLead]="c.lead"></app-form-heading>
      <app-form-select
        *ngIf="c.type === 'dropdown_select' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [options]="returnAsOptionArray(c.options)"
        [customOptionEnabled]="c.customOptionEnabled"
        [placeholder]="c.placeholder"
        [formControl]="getFormControl(c.id)"
        [width]="100"
      ></app-form-select>
      <app-text-input
        *ngIf="c.type === 'simple_input' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [formControl]="getFormControl(c.id)"
        [placeholder]="c.placeholder"
        [charMin]="c.minimumCharacterLimit"
        [charMax]="c.maximumCharacterLimit"
        [regex]="c.regex"
      ></app-text-input>
      <app-scale
        *ngIf="c.type === 'rating' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [answers]="c.answers"
        [title]="c.title"
        [lead]="c.lead"
        [questions]="c.questions"
        [required]="!!c.required"
      ></app-scale>
      <app-scale
        *ngIf="c.type === 'questionare' && isFormFieldVisible(c)"
        [lead]="c.lead"
        [formControl]="getFormControl(c.id)"
        [title]="c.title"
        [answers]="returnAsOptionArray(c.options)"
        [questions]="c.questions"
        [type]="'alternative'"
        [required]="!!c.required"
      ></app-scale>
      <app-radio-select
        *ngIf="c.type === 'radio_group' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [title]="c.title"
        [options]="returnAsOptionArray(c.options)"
        [formControl]="getFormControl(c.id)"
      ></app-radio-select>
      <app-custom-date-picker
        *ngIf="c.type === 'date_interval_picker' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [dateRangePicker]="true"
        [title]="c.title"
        [beginDateWithTime]="!!c.beginDateWithTime"
        [endDateWithTime]="!!c.endDateWithTime"
        [required]="!!(c.beginDateRequired || c.endDateRequired)"
      >
      </app-custom-date-picker>
      <app-custom-date-picker
        *ngIf="c.type === 'date_picker' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [title]="c.title"
        [withTime]="c.withTime"
        [required]="!!c.required"
      >
      </app-custom-date-picker>
      <app-checkbox-group
        [options]="returnAsOptionArray(c.options)"
        *ngIf="c.type === 'checkbox_group' && isFormFieldVisible(c)"
        [title]="c.title"
        [minChoices]="c.minimumChoiceLimit"
        [formControl]="getFormControl(c.id)"
        [maxChoices]="c.maximumChoiceLimit"
        [required]="!!c.required"
      >
      </app-checkbox-group>
      <app-listing
        *ngIf="c.type === 'ranking' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [requiredSelectedAmount]="c.minimumChoiceLimit"
        [maximumSelectedAmount]="c.maximumChoiceLimit"
        [optionTitle]="c.select!.title"
        [optionLead]="c.select!.lead"
        [cbOrder]="c.select!.serialNumberHeader"
        [customOptionEnabled]="!!c.customOptionEnabled"
        [cbOption]="c.select!.optionHeader"
        [cbCheckbox]="c.select!.checkboxHeader"
        [selectorLead]="c.order!.lead"
        [selectorTitle]="c.order!.title"
        [selOrder]="c.order!.serialNumberHeader"
        [selOption]="c.order!.optionHeader"
        [selText]="c.order!.checkboxHeader"
        [ordering]="c.serialNumbersEnabled"
        [required]="!!c.required"
        [options]="returnAsOptionArray(c.options)"
      >
      </app-listing>
      <app-listing
        *ngIf="c.type === 'complex_ranking' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [requiredSelectedAmount]="c.minimumChoiceLimit"
        [maximumSelectedAmount]="c.maximumChoiceLimit"
        [optionTitle]="c.select!.title"
        [optionLead]="c.select!.lead"
        [cbOrder]="c.select!.serialNumberHeader"
        [customOptionEnabled]="!!c.customOptionEnabled"
        [cbOption]="c.select!.optionHeader"
        [cbCheckbox]="c.select!.checkboxHeader"
        [selectorLead]="c.order!.lead"
        [selectorTitle]="c.order!.title"
        [selOrder]="c.order!.serialNumberHeader"
        [selOption]="c.order!.optionHeader"
        [selText]="c.order!.checkboxHeader"
        [ordering]="c.serialNumbersEnabled"
        [required]="!!c.required"
        [options]="returnAsOptionArray(c.options)"
        [screen2Lead]="c.screen2.lead"
        [screen2Title]="c.screen2.title"
        [screen2checkboxHeader]="c.screen2.checkboxHeader"
        [screen2OptionHeader]="c.screen2.optionHeader"
        [screen2SerialHeader]="c.screen2.serialNumberHeader"
        [activities]="c.screen2.options"
        [screen3Lead]="c.screen3.lead"
        [screen3Title]="c.screen3.title"
        [screen3checkboxHeader]="c.screen3.checkboxHeader"
        [screen3OptionHeader]="c.screen3.optionHeader"
        [screen3SerialHeader]="c.screen3.serialNumberHeader"
        [screen2Required]="!!c.screen2Required"
        [screen3Required]="!!c.screen3Required"
        [regions]="c.screen3.options"
      >
        >
      </app-listing>

      <app-phone-input
        *ngIf="c.type === 'phone_number' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [formControl]="getFormControl(c.id)"
        [placeholder]="c.placeholder"
        [phoneRegex]="c.phoneNumberRegex"
        [countryCode]="c.countryCodeRegex"
      >
      </app-phone-input>
      <app-text-input
        *ngIf="c.type === 'email' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [formControl]="getFormControl(c.id)"
        [placeholder]="c.placeholder"
        [ariaLabel]="c.ariaLabel"
        [regex]="c.regex"
      >
      </app-text-input>

      <app-textarea-input
        *ngIf="c.type === 'textarea_input' && isFormFieldVisible(c)"
        [formControl]="getFormControl(c.id)"
        [charLimit]="c.maximumCharacterLimit"
        [placeholder]="c.placeholder"
        [required]="!!c.required"
      ></app-textarea-input>
      <app-document-acceptance
        *ngIf="c.type === 'document_agree' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [title]="c.title"
        [formControl]="getFormControl(c.id)"
        [lead]="c.lead"
        [link]="c.link"
      ></app-document-acceptance>
      <app-file-uploader
        *ngIf="c.type === 'file_upload' && isFormFieldVisible(c)"
        [required]="!!c.required"
        [allowedFileData]="returnAsAllowedFileDataArray(c.options)"
        [numberOfFilesRequired]="c.minimumFileLimit"
        [formControl]="getFormControl(c.id)"
        [uploadedFileLinks]="c.links"
        [id]="c.id"
      ></app-file-uploader>
      <app-form-table
        *ngIf="c.type === null && isFormFieldVisible(c)"
        [tableId]="c.id"
        [title]="c.title"
        [columns]="c.columns"
        [maxColumn]="c.maximumRowLimit"
        [minColumn]="c.minimumRowLimit"
        [formControl]="getFormControl(c.id)"
      ></app-form-table>
      <app-quantity
        *ngIf="c.type === 'quantity' && isFormFieldVisible(c)"
        [options]="returnAsOptionArray(c.options)"
        [title]="c.title"
        [required]="!!c.required"
        [placeholder]="c.placeholder"
        [formControl]="getFormControl(c.id)"
      ></app-quantity>
      <div *ngIf="c.type === 'wysiwyg' && isFormFieldVisible(c)" class="wysiwyg-component" [innerHTML]="sanitizedContent(c.content)"></div>
      <div *ngIf="c.type === 'spacer'" class="spacer"></div>
    </div>
  </form>
  <div class="err" *ngIf="selectedPage === formData.pages.length - 1 && faultyFormPages.length">
    {{ faultyFormPagesError }}
  </div>
  <div class="form-footer">
    <div class="buttons">
      <button class="button" *ngIf="selectedPage !== 0" (click)="prev()">{{ 'buttons.back' | translate }}</button>
      <button class="button" *ngIf="(selectedPage !== formData.pages.length - 1) && visiblePagesCount - 1 > selectedPage" (click)="next()" [disabled]="getIsNextDisabled()">
        {{ 'buttons.next' | translate }}
      </button>
      <button
        class="button button--pink"
        *ngIf="(selectedPage === formData.pages.length - 1) || (selectedPage === visiblePagesCount - 1)"
        [disabled]="form.invalid || (fillId && !queryParams.submitSignature && queryParams.signature) || form.disabled"
        (click)="submitForm()"
      >
      {{ 'buttons.submit' | translate }}
      </button>
    </div>
    <div *ngIf="formData.pages.length > 1" class="page-number">
      <p>{{ selectedPage + 1 }}. {{ 'texts.page' | translate }}</p>
    </div>
  </div>
</form>
