import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ApiService } from '../../services/api.service';
import { SharedService } from '../../services/shared.service';
import { FOCUSABLE_ELEMENTS_QUERY, IArticleFilters, IFilterOption } from '../../shared.definitions';

@Component({
  selector: 'app-article-collection',
  templateUrl: './article-collection.component.html',
  styleUrls: ['./article-collection.component.scss'],
})
export class ArticleCollectionComponent implements OnInit {
  @ViewChild('acFilterResults') searchResultsContainer: ElementRef;
  articles: any[];
  activeFilters: IArticleFilters;
  maxArticleCount: number;
  articlesPerPage: number;
  limitMultiplier: number;
  firstLoaded = false;
  collectionUUID: string;
  showAlertMessage = false;

  private prevScrollPos: any;

  constructor(
    private readonly apiService: ApiService,
    private readonly localize: LocalizeRouterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.defaultRequest();
  }

  defaultRequest(): void {
    this.firstLoaded = false;
    this.articlesPerPage = 6;
    this.limitMultiplier = 1;
    const filters: any = {
      search: '',
      destinations: [],
      thematics: [],
    };
    this.activeFilters = {
      search: '',
      destinations: [],
      thematics: [],
    };
    this.articles = [];
    this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.ArticleCollection') as string;

    this.apiService.getArticles$(0, this.articlesPerPage * this.limitMultiplier, this.collectionUUID).subscribe((res) => {
      Object.keys(res.meta.filterOptions.destinationTags_filter).forEach((key) => {
        filters.destinations.push({
          id: +key,
          text: res.meta.filterOptions.destinationTags_filter[key],
          checked: false,
        });
      });
      Object.keys(res.meta.filterOptions.thematicTags_filter).forEach((key) => {
        filters.thematics.push({
          id: +key,
          text: res.meta.filterOptions.thematicTags_filter[key],
          checked: false,
        });
      });
      this.activeFilters = JSON.parse(JSON.stringify(filters));
      this.setFiltersFromQueryParams();
      const queryParams = this.route.snapshot.queryParams;
      this.maxArticleCount = res.meta.limit.allRowCount;
      if (
        queryParams['destinationTags_filter'] ||
        queryParams['global_filter'] ||
        queryParams['tripLength_filter'] ||
        queryParams['targetAudienceTags_filter']
      ) {
        this.filterRequest(false);
        this.firstLoaded = true;
      } else {
        this.articles = res.data.map((a: any) => {
          const translatedRoute = <string[]>this.localize.translateRoute(['/', 'articles']);
          translatedRoute.push(a.slug);
          return {
            image: a.thumbnail,
            routerLink: translatedRoute,
            title: a.title,
            lead: a.lead,
            color: { value: 'EF4E69' },
          };
        });
        this.firstLoaded = true;
      }
    });
  }

  filterRequest(isAddition: boolean, shouldBeAlerted = false): void {
    this.showAlertMessage = false;
    if (!this.anyFilterActive()) {
      this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.ArticleCollection') as string;
    } else {
      this.collectionUUID = '';
    }

    this.apiService
      .getArticles$(
        this.articlesPerPage * (this.limitMultiplier - 1),
        this.articlesPerPage * this.limitMultiplier,
        this.collectionUUID,
        this.activeFilters.search,
        this.activeFilters.destinations,
        this.activeFilters.thematics
      )
      .subscribe((res) => {
        if (isAddition) {
          const addition = res.data.map((a: any) => {
            const translatedRoute = <string[]>this.localize.translateRoute(['/', 'articles']);
            translatedRoute.push(a.slug);
            return {
              image: a.thumbnail,
              routerLink: translatedRoute,
              title: a.title,
              lead: a.lead,
              color: { value: 'EF4E69' },
            };
          });
          this.articles = [...this.articles, ...addition];
          this.scrollToPrevPos();
        } else {
          this.articles = res.data.map((a: any) => {
            const translatedRoute = <string[]>this.localize.translateRoute(['/', 'articles']);
            translatedRoute.push(a.slug);
            return {
              image: a.thumbnail,
              routerLink: translatedRoute,
              title: a.title,
              lead: a.lead,
              color: { value: 'EF4E69' },
            };
          });
        }
        this.showAlertMessage = shouldBeAlerted;
        this.maxArticleCount = res.meta.limit.allRowCount;
        this.updateQueryParams();
      });
  }

  loadMore(): void {
    this.prevScrollPos = window.scrollY;
    this.limitMultiplier += 1;
    this.filterRequest(true);
  }

  scrollToPrevPos(): void {
    setTimeout(() => {
      document.getElementsByTagName('html')[0].style.setProperty('scroll-behavior', 'unset');
      window.scroll({ top: this.prevScrollPos, behavior: 'auto' });
    }, 0);
  }

  onFilterChange(event: IArticleFilters): void {
    this.activeFilters = event;
    this.limitMultiplier = 1;
    this.filterRequest(false, true);
    if (event.scroll) {
      this.scrollToSearchResults();
    }
  }

  anyFilterActive(): boolean {
    return (
      this.activeFilters.destinations.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.thematics.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.search.length > 0
    );
  }

  removeFilterElement(filterOption: IFilterOption): void {
    filterOption.checked = false;
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest(false);
  }

  clearFilters(): void {
    if (this.activeFilters.destinations.length > 0) {
      this.activeFilters.destinations.forEach((item) => {
        item.checked = false;
      });
    }
    if (this.activeFilters.thematics.length > 0) {
      this.activeFilters.thematics.forEach((item) => {
        item.checked = false;
      });
    }
    this.activeFilters.search = '';
    this.activeFilters = Object.assign({}, this.activeFilters);
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest(false);
  }

  private scrollToSearchResults(): void {
    this.searchResultsContainer.nativeElement.scrollIntoView();
    const focusableElements = this.searchResultsContainer.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS_QUERY);
    if (focusableElements?.length) {
      focusableElements[0].focus();
    }
  }

  private updateQueryParams(): void {
    const destinations = this.activeFilters.destinations;
    const thematics = this.activeFilters.thematics;

    const queryParams: Params = {};

    if (this.activeFilters.search) {
      queryParams['global_filter'] = this.activeFilters.search;
    }
    if (destinations && destinations.length && destinations.some((f) => f.checked)) {
      queryParams['destinationTags_filter'] = destinations.filter((f) => f.checked).map((f) => f.id);
    }
    if (thematics && thematics.length && thematics.some((f) => f.checked)) {
      queryParams['thematicTags_filter'] = thematics.filter((f) => f.checked).map((f) => f.id);
    }
    queryParams['scroll'] = false;
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private setFiltersFromQueryParams(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['global_filter']) {
      this.activeFilters.search = queryParams['global_filter'];
    }
    if (queryParams['destinationTags_filter']) {
      const destinationTags_filter = Array.isArray(queryParams['destinationTags_filter'])
        ? queryParams['destinationTags_filter'].map((id) => +id)
        : [+queryParams['destinationTags_filter']];

      this.activeFilters.destinations = this.activeFilters.destinations.map((filter) => ({
        ...filter,
        checked: destinationTags_filter.indexOf(filter.id) !== -1,
      }));
    }
    if (queryParams['thematicTags_filter']) {
      const thematicTags_filter = Array.isArray(queryParams['thematicTags_filter'])
        ? queryParams['thematicTags_filter'].map((id) => +id)
        : [+queryParams['thematicTags_filter']];

      this.activeFilters.thematics = this.activeFilters.thematics.map((filter) => ({
        ...filter,
        checked: thematicTags_filter.indexOf(filter.id) !== -1,
      }));
    }
  }
}
