import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-b2b-collection',
  templateUrl: './b2b-collection.component.html',
  styleUrls: ['./b2b-collection.component.scss'],
})
export class B2bCollectionComponent extends BaseComponent implements OnInit, OnDestroy {
  data: any;
  counter = 1;
  offsetLimit = 3;
  isLoadMoreButtonNeed: boolean;
  listType: string;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  search = new UntypedFormControl('');
  filters: Array<any>;
  activeFilters: any = [];

  constructor(
    private readonly apiService: ApiService,
    private readonly localizeRouterService: LocalizeRouterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.component?.type) {
      this.getListType();

      this.search.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe(() => this.handleSearch());

      this.fetchUrlParams();

      if (this.search.value.length === 0) {
        this.fetchData();
      }
    }
  }

  getListType(): void {
    switch (this.component.type) {
      case 'Body.Collection.B2bArticleCollection':
        this.listType = 'B2bArticles';
        break;
      case 'Body.Collection.B2bNewsCollection':
        this.listType = 'B2bNews';
        break;
      case 'Body.Collection.B2bEventCollection':
        this.listType = 'B2bEvents';
        break;
      default:
    }
  }

  fetchData(): void {
    this.apiService
      .getContentListByParams$(this.listType, {
        rowFrom_limit: this.offsetLimit * (this.counter - 1),
        rowTo_limit: this.offsetLimit * this.counter,
        collection: this.component.uuid,
        global_filter: this.search.value,
        storyIdeasTags_filter: this.activeFilters,
      })
      .subscribe((res) => {
        const data = res.data.map((a: any) => {
          const translatedRoute = <string[]>this.localizeRouterService.translateRoute(['/b2b', 'news']);
          translatedRoute.push(a.slug);
          return {
            image: a.thumbnail,
            routerLink: translatedRoute,
            title: a.title,
            lead: a.lead,
            color: { value: 'EF4E69' },
          };
        });

        const filters = res.meta?.filterOptions?.storyIdeasTags_filter;
        if (!this.filters) {
          this.filters = Object.keys(filters).map((id) => {
            return {
              id: id,
              name: filters[id],
              checked: this.activeFilters.includes(id),
            };
          });
        }

        if (this.counter === 1) {
          this.data = data;
        } else {
          this.data = !this.data?.length ? data : [...this.data, ...data];
        }

        this.isLoadMoreButtonNeed = res.meta?.limit?.allRowCount > this.data?.length;
      });
  }

  onClickLoadMore(): void {
    this.counter++;
    this.fetchData();
  }

  handleSearch(): void {
    this.counter = 1;

    this.setUrlParams();
    this.fetchData();
  }

  handleFilter(filter: any): void {
    this.filters.map((item) => {
      if (item.id === filter.id) {
        item.checked = !item.checked;
      }
    });

    if (this.activeFilters.includes(filter.id)) {
      const index = this.activeFilters.indexOf(filter.id);
      this.activeFilters.splice(index, 1);
    } else {
      this.activeFilters.push(filter.id);
    }

    this.counter = 1;

    this.setUrlParams();
    this.fetchData();
  }

  setUrlParams(): void {
    const params: Params = {};

    if (this.search.value) {
      params['search'] = this.search.value;
    }

    if (this.activeFilters.some((f: any) => f)) {
      params['storyIdeasTags_filter'] = this.activeFilters.map((f: any) => f);
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
  }

  fetchUrlParams(): void {
    const params: Params = this.activatedRoute.snapshot.queryParams;

    if (params['search']) {
      this.search.setValue(params['search']);
    }

    if (params['storyIdeasTags_filter']) {
      this.activeFilters = [...params['storyIdeasTags_filter']];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
