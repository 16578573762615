import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { ApiService } from '../../services/api.service';
import { DateLabelService } from '../../services/date-label.service';
import { SharedService } from '../../services/shared.service';
import { FOCUSABLE_ELEMENTS_QUERY, IEventFilters, IFilterOption } from '../../shared.definitions';

@Component({
  selector: 'app-event-collection',
  templateUrl: './event-collection.component.html',
  styleUrls: ['./event-collection.component.scss'],
})
export class EventCollectionComponent implements OnInit {
  @ViewChild('ecFilterResults') searchResultsContainer: ElementRef;
  events: any[];
  datedEvents: any[] = [];
  activeFilters: IEventFilters;
  maxEventCount: number;
  eventsPerPage = 9;
  limitMultiplier = 1;
  monthFormat = 'yyyy-MM';
  collectionUUID: string;

  private prevScrollPos: any;

  constructor(
    private readonly apiService: ApiService,
    private readonly localize: LocalizeRouterService,
    private readonly dateLabelService: DateLabelService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.defaultRequest();
  }

  defaultRequest(): void {
    this.activeFilters = {
      search: '',
      dateRange: [],
      destinations: [],
      thematics: [],
      isArchive: false,
    };
    this.events = [];
    this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.EventCollection') as string;

    this.apiService.getEvents$(0, this.eventsPerPage * this.limitMultiplier, this.collectionUUID).subscribe((res) => {
      Object.keys(res.meta.filterOptions.destinationTags_filter).forEach((key) => {
        this.activeFilters.destinations.push({
          id: +key,
          text: res.meta.filterOptions.destinationTags_filter[key],
          checked: false,
        });
      });
      Object.keys(res.meta.filterOptions.thematicTags_filter).forEach((key) => {
        this.activeFilters.thematics.push({
          id: +key,
          text: res.meta.filterOptions.thematicTags_filter[key],
          checked: false,
        });
      });

      this.setFiltersFromQueryParams();
      this.maxEventCount = res.meta.limit.allRowCount;

      const queryParams = this.route.snapshot.queryParams;

      if (Object.keys(queryParams)?.length) {
        this.filterRequest();
      } else {
        this.events = this.getTransformedEventData(res.data);
        this.mapEventsToDates(this.events);
      }
    });
  }

  filterRequest(): void {
    if (!this.anyFilterActive()) {
      this.collectionUUID = this.sharedService.getCollectionUUID(this.route.snapshot, 'Body.Collection.EventCollection') as string;
    } else {
      this.collectionUUID = '';
    }

    this.apiService
      .getEvents$(
        this.eventsPerPage * (this.limitMultiplier - 1),
        this.eventsPerPage * this.limitMultiplier,
        this.collectionUUID,
        this.activeFilters.search,
        this.activeFilters.destinations,
        this.activeFilters.thematics,
        this.activeFilters.dateRange,
        this.activeFilters.isArchive
      )
      .subscribe((res) => {
        const eventData = this.getTransformedEventData(res.data);

        if (this.limitMultiplier === 1) {
          this.events = eventData;
        } else {
          this.events = [...this.events, ...eventData];
          this.scrollToPrevPos();
        }

        this.mapEventsToDates(this.events);
        this.maxEventCount = res.meta.limit.allRowCount;
        this.updateQueryParams();
      });
  }

  getTransformedEventData(data: any): any {
    return data.map((a: any) => {
      const translatedRoute = <string[]>this.localize.translateRoute(['/', 'events']);
      translatedRoute.push(a.slug);
      return {
        eventDateFrom: a.status.eventDateFrom,
        eventDateTill: a.status.eventDateTill,
        image: a.thumbnail,
        event: a,
        routerLink: translatedRoute,
        title: a.title,
        lead: a.lead,
        destination: a.relation.destinationTags[0].label,
      };
    });
  }

  mapEventsToDates(events: any[]): void {
    this.datedEvents = [];
    if (events.length >= 1 && this.activeFilters.dateRange.length < 1) {
      events.forEach((item) => {
        const yearMonth = this.toYearMonth(item.eventDateFrom);
        const machingDatedEvents = this.datedEvents.find((i) => i.yearMonth === yearMonth);
        if (machingDatedEvents === undefined) {
          this.datedEvents.push({
            events: [item],
            monthLabel: this.dateLabelService.fetchTranslatedMonthNameFromMoment(item.eventDateFrom),
            yearMonth: yearMonth,
          });
        } else {
          machingDatedEvents.events.push(item);
        }
      });
      this.datedEvents.sort((n1, n2) => {
        if (n1.yearMonth > n2.yearMonth) {
          return 1;
        }
        if (n1.yearMonth < n2.yearMonth) {
          return -1;
        }
        return 0;
      });
    } else {
      this.datedEvents = [];
    }
  }

  loadMore(): void {
    this.prevScrollPos = window.scrollY;
    this.limitMultiplier += 1;
    this.filterRequest();
  }

  scrollToPrevPos(): void {
    setTimeout(() => {
      document.getElementsByTagName('html')[0].style.setProperty('scroll-behavior', 'unset');
      window.scroll({ top: this.prevScrollPos, behavior: 'auto' });
    }, 0);
  }

  onFilterChange(event: IEventFilters): void {
    this.activeFilters = event;
    if (this.activeFilters?.dateRange?.length) {
      this.activeFilters.dateRange[0] = new Date(this.activeFilters.dateRange[0]);
      this.activeFilters.dateRange[1] = new Date(this.activeFilters.dateRange[1]);
    }
    this.limitMultiplier = 1;
    this.filterRequest();
    if (event.scroll) {
      this.scrollToSearchResults();
    }
  }

  private scrollToSearchResults(): void {
    this.searchResultsContainer.nativeElement.scrollIntoView();
    const focusableElements = this.searchResultsContainer.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS_QUERY);
    if (focusableElements?.length) {
      focusableElements[0].focus();
    }
  }

  anyFilterActive(): boolean | undefined {
    return (
      this.activeFilters.destinations.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.thematics.findIndex((f) => f.checked) > -1 ||
      this.activeFilters.search.length > 0 ||
      this.activeFilters.dateRange.length > 0 ||
      this.activeFilters.isArchive
    );
  }

  removeFilterElement(filterOption: IFilterOption): void {
    filterOption.checked = false;
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest();
  }

  removeDateFilterElement(): void {
    this.activeFilters.dateRange = [];
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest();
  }

  clearFilters(): void {
    if (this.activeFilters?.destinations?.length) {
      this.activeFilters.destinations.forEach((item) => {
        item.checked = false;
      });
    }
    if (this.activeFilters?.thematics?.length) {
      this.activeFilters.thematics.forEach((item) => {
        item.checked = false;
      });
    }
    this.activeFilters.search = '';
    this.activeFilters.dateRange = [];
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.activeFilters.isArchive = false;
    this.filterRequest();
  }

  toYearMonth(date: Date): string {
    return this.dateLabelService.getYearMonth(date);
  }

  dateLabel(date1: string | Date, date2: string | Date): string {
    return this.dateLabelService.createLabel(date1, date2);
  }

  private updateQueryParams(): void {
    const destinations = this.activeFilters.destinations;
    const eventDateGt = this.activeFilters.dateRange && this.activeFilters.dateRange[0];
    const eventDateGt_filter = new DateFormatPipe().transform(eventDateGt, 'YYYY-MM-DD');
    const eventDateLt = this.activeFilters.dateRange && this.activeFilters.dateRange[1];
    const eventDateLt_filter = new DateFormatPipe().transform(eventDateLt, 'YYYY-MM-DD');
    const thematics = this.activeFilters.thematics;

    const queryParams: Params = {};

    if (this.activeFilters.search) {
      queryParams['global_filter'] = this.activeFilters.search;
    }
    if (destinations && destinations.length && destinations.some((f) => f.checked)) {
      queryParams['destinationTags_filter'] = destinations.filter((f) => f.checked).map((f) => f.id);
    }
    if (eventDateGt_filter) {
      queryParams['eventDateGt_filter'] = eventDateGt_filter;
    }
    if (eventDateLt_filter) {
      queryParams['eventDateLt_filter'] = eventDateLt_filter;
    }
    if (thematics && thematics.length && thematics.some((f) => f.checked)) {
      queryParams['thematicTags_filter'] = thematics.filter((f) => f.checked).map((f) => f.id);
    }
    if (this.activeFilters.isArchive) {
      queryParams['isArchive'] = this.activeFilters.isArchive;
    }
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private setFiltersFromQueryParams(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['global_filter']) {
      this.activeFilters.search = queryParams['global_filter'];
    }
    if (queryParams['eventDateGt_filter'] && queryParams['eventDateLt_filter']) {
      this.activeFilters.dateRange = [new Date(queryParams['eventDateGt_filter']), new Date(queryParams['eventDateLt_filter'])];
    }
    if (queryParams['destinationTags_filter']) {
      const destinationTags_filter = Array.isArray(queryParams['destinationTags_filter'])
        ? queryParams['destinationTags_filter'].map((id) => +id)
        : [+queryParams['destinationTags_filter']];

      this.activeFilters.destinations = this.activeFilters.destinations.map((filter) => ({
        ...filter,
        checked: destinationTags_filter.indexOf(filter.id) !== -1,
      }));
    }
    if (queryParams['thematicTags_filter']) {
      const thematicTags_filter = Array.isArray(queryParams['thematicTags_filter'])
        ? queryParams['thematicTags_filter'].map((id) => +id)
        : [+queryParams['thematicTags_filter']];

      this.activeFilters.thematics = this.activeFilters.thematics.map((filter) => ({
        ...filter,
        checked: thematicTags_filter.indexOf(filter.id) !== -1,
      }));
    }
    if (queryParams['isArchive']) {
      this.activeFilters.isArchive = queryParams['isArchive'];
    }
  }
}
